<template>
    <div>
        <div v-if="loading" class="loading-wrap">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <!--ЗАРЛАГДСАН АЖЛУУД-->
                <div class="left">
                    <div class="job_news_title">
                        <div class="img"><img src="../../assets/paint_icon.svg" alt="Icon"></div>
                        <div class="text_line_title">
                            <!--<span>{{ 'ajliin_bairnii_zar' | translate }}</span>-->
                            <span>{{ 'brigad_ajliin_zar' | translate }}</span>
                            <svg class="svg_text_line">
                                <line x1="80" style="stroke:#EFBC11; stroke-width:3;"/>
                            </svg>
                            <a href="javascript:void(0)" @click="cleanFilter" class="clean_button">
                                <span>{{ 'clear' | translate }}</span>
                            </a>
                        </div>
                    </div>

                    <div class="tab_main">
                        <p>{{ 'brigad' | translate }}</p>
                        <Select class="job_select" v-model="selectBrigad" :placeholder="'brigadaar_haih' | translate " filterable>
                            <Option v-for="(brigad, index) in select_ds_brigad_view" :value="brigad.id" :key="index">
                                <div v-if="language.short == 'mn'"> {{ brigad.ner_mn }}</div>
                                <div v-else>{{ brigad.ner_en }}</div>
                            </Option>
                        </Select>
                        <p class="">{{ 'job' | translate }}</p>
                        <Select class="job_select" v-model="selectAJilTurul" :placeholder="'ajliin_turul' | translate " filterable>
                            <Option v-for="(turul, index) in lut_ajiliin_torol" :value="turul.id" :key="index">
                                {{ turul.ajiliin_torol }}
                            </Option>
                        </Select>
                        <p>{{ 'tsagiin_turul' | translate }}</p>
                        <Select class="job_select" v-model="selectTsagiinTurul"
                                :placeholder="'tsagiin_turul_songoh' | translate">
                            <Option v-for="(tsturul, index) in ds_tsag_view" :value="tsturul.id" :key="index">
                                {{ tsturul.tsagiin_torol }}
                            </Option>
                        </Select>

                        <p>{{ 'tsalin_huls' | translate }}</p>
                        <Select class="job_select" v-model="selectTsalinTurul"
                                :placeholder="'tsalingiin_unelgee_songoh' | translate">
                            <Option v-for="(tsalin, index) in ds_tsalin_view" :value="tsalin.id" :key="index">
                                {{ tsalin.tsalin_torol }}
                            </Option>
                        </Select>

                        <p>{{ 'aimag_niislel' | translate }}</p>
                        <Select class="job_select" v-model="selectAimagNiislelBrgd"
                                on-select="getSumDuureg"
                                :placeholder="'aimagcapitalcity' | translate">
                            <Option v-for="(item, index) in aimag_niislelBrgd" :value="item.id" :key="index">
                                {{ item.aimagname }}
                            </Option>
                        </Select>

                        <p>{{ 'sum_duureg' | translate }}</p>
                        <Select class="job_select1" v-model="selectSumDuuregBrgd"
                                :placeholder="'sumdistrict' | translate">
                            <Option v-for="(item, index) in SumDvvreg" :value="item.id" :key="index">
                                {{ item.sumname }}
                            </Option>
                        </Select>
                    </div>

                    <div class="search">
                        <Button @click="callDataBrigad" class="search_btn">
                            <i class="ivu-icon ivu-icon-ios-search"></i>
                            {{ 'search' | translate }}
                        </Button>
                    </div>
                </div>

                <!--banner-->
                <div class="c_banner">
                    <a><img :src="IMAGE_URL + banner4.unelgee" alt="Image"></a>
                </div>
            </div>
            <!--Right Slide and table list-->
            <div class="col-md-8">
                <div class="right">
                    <div class="top_item">
                        <brigad_slide></brigad_slide>
                    </div>
                    <!--Right table list (Ажлын зарын лист)-->
                    <div class="p_table" v-if="brigad_ajiliin_zar.length > 0">
                        <table width="100%">
                            <tbody>
                            <!--tr-->
                            <tr v-for="(zar, briZarIndex) in brigad_ajiliin_zar" :key="briZarIndex">
                                <td>
                                    <div class="company_logos" v-if="zar.torol_gishuun">
                                        <img class="logo" src="../../assets/company_logo/1_gold.svg" alt=""
                                             v-if="zar.torol_gishuun === 'Алтан багц'">
                                        <img class="logo" src="../../assets/company_logo/1_silver.svg" alt=""
                                             v-if="zar.torol_gishuun === 'Мөнгөн багц'">
                                        <img class="logo" src="../../assets/company_logo/1_bronze.svg" alt=""
                                             v-if="zar.torol_gishuun === 'Хүрэл багц'">
                                    </div>
                                    <div class="company_logos" v-else>
                                        <img class="logo" src="../../assets/company_logo/2_gold.svg" alt=""
                                             v-if="zar.torol_gishuun_bus === 'Алтан багц'">
                                        <img class="logo" src="../../assets/company_logo/2_silver.svg" alt=""
                                             v-if="zar.torol_gishuun_bus === 'Мөнгөн багц'">
                                        <img class="logo" src="../../assets/company_logo/2_bronze.svg" alt=""
                                             v-if="zar.torol_gishuun_bus === 'Хүрэл багц'">
                                    </div>
                                    <div class="p_logo">
                                        <img :src="IMAGE_URL + zar.logo" alt="" v-if="zar.logo != ''">
                                        <img src="../../assets/no-image.png" alt="" v-else>
                                    </div>
                                </td>
                                <td>
                                    <div class="col_1 ">
                                        <router-link :to="`/br_a_z_details/${zar.id}`"><h5>{{ zar.ajiliin_torol }}</h5>
                                        </router-link>
                                        <div class="flex_col">
                                            <div class="sub_col_2">
                                                <span class="company_n">{{ zar.ner_mnbaiguullaga }} </span>
                                                <div class="text_bold">{{ 'where' | translate }}: <span
                                                        class="not_bold"> {{ zar.aimagname }} </span></div>
                                                <div class="text_bold">Цалин: <span
                                                        class="not_bold"> {{ zar.sanalbolgoh_tsalin }} </span></div>
                                            </div>
                                            <div class="static_button ">
                                                {{ zar.tsagiin_torol }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <save_button_brigad_ajil_zar :zar_id="zar.id" type="brigad"></save_button_brigad_ajil_zar>
                                    <div class="td_3">
                                        <div class="td_3_1">
                                            <p>{{ 'zarlasan_ognoo' | translate }}</p>
                                            <p class="diff_p_text">{{ zar.e_ognoo | date }}</p>
                                        </div>
                                        <div class="td_3_2">
                                            <p>{{ 'anket_avah_ognoo' | translate }}</p>
                                            <p class="p_text ">{{ zar.d_ognoo | date }}</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="page">
                            <Page @on-change="changePageBrigad"
                                  :current-page="current_page"
                                  :page-size="10"
                                  :total="total"
                                  size="small"
                            />
                        </div>
                    </div>
                    <div v-else class="no-data">
                        {{ 'no_ads' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {
    GET_BANNER_VIEW,
    GET_BRGD_AJIL_ZAR_PAGE_BY_ID,
    GET_BRGD_AJILIIN_ZAR_PAGE,
    GET_BRIGAD_VIEW,
    GET_TSAGIIN_TURUL,
    GET_TSALIN_TURUL,
    IMAGE_URL,
    GET_AJILIIN_TURUL, GET_AIMAG_NIISLEL, GET_SUM_DUUREG_ALL,
} from "../../graphql/queries";
import {mapGetters} from "vuex";

const save_button_brigad_ajil_zar = () => import(/* webpackChunkName: "save_button_brigad_ajil_zar" */ '../section/save_button_brigad_ajil_zar');
const brigad_slide = () => import(/* webpackChunkName: "brigad_slide" */ '../section/brigad_slide');
export default {
    components: {
        "save_button_brigad_ajil_zar": save_button_brigad_ajil_zar,
        "brigad_slide": brigad_slide,
    },
    data() {
        return {
            loading: true,
            IMAGE_URL: IMAGE_URL,
            ds_brigad_view: [],
            select_ds_brigad_view: [],
            ds_tsalin_view: [],
            ds_tsag_view: [],
            elect_ds_brigad_view: [],
            lut_ajiliin_torol: [],
            total: 0,
            current_page: 1,
            last_page: 1,
            selectBrigad: '',
            selectTsagiinTurul: '',
            selectTsalinTurul: '',
            selectAJilTurul: '',
            brigad_ajiliin_zar: [],
            banner4: {},

            selectAimagNiislelBrgd: '',
            selectSumDuuregBrgd: '',
            aimag_niislelBrgd: [],
            sumduuregBrgd: [],

        }
    },
    computed: {
        ...mapGetters([
            'languages',
            'language',
            // ...
        ]),
        SumDvvreg() {
            if (this.selectAimagNiislelBrgd) {
                return this.sumduuregBrgd.filter(sumdvvreg => sumdvvreg.aimagid == this.selectAimagNiislelBrgd);
            } else {
                return this.sumduuregBrgd;
            }
        },
    },
    methods: {
        getBrigad_select() {
            this.$apollo.query({query: GET_BRIGAD_VIEW}).then(({data}) => {
                this.select_ds_brigad_view = data.ds_brilgas_view;
            })
        },
        getTsagiinTurul() {
            this.$apollo.query({query: GET_TSAGIIN_TURUL}).then(({data}) => {
                this.ds_tsag_view = data.ds_tsag_view;
            })
        },
        getTsalinTurul() {
            this.$apollo.query({query: GET_TSALIN_TURUL}).then(({data}) => {
                this.ds_tsalin_view = data.ds_tsalin_view;
            })
        },
        getAjilTurul() {
            this.$apollo.query({query: GET_AJILIIN_TURUL}).then(({data}) => {
                this.lut_ajiliin_torol = data.lut_ajiliin_torol;
            })
        },
        callDataBrigad() {
            // console.log(this.selectTsagiinTurul);
            // console.log(this.selectTsalinTurul);
            // console.log(this.selectAJilTurul);
            // console.log(this.selectBrigad);
            this.loading = true;
            if (this.selectBrigad || this.selectAJilTurul || this.selectTsagiinTurul || this.selectTsalinTurul || this.selectAimagNiislelBrgd || this.selectSumDuuregBrgd) {
                this.$apollo.query({
                    query: GET_BRGD_AJIL_ZAR_PAGE_BY_ID, fetchPolicy: 'no-cache',
                    variables: {
                        page: this.current_page, size: 10,
                        brigad_id: this.selectBrigad.toString(),
                        ts_turul_id: this.selectTsagiinTurul.toString(),
                        tsalin_turul_id: this.selectTsalinTurul.toString(),
                        ajiliin_turul_id: this.selectAJilTurul.toString(),
                        aimag_niislel: this.selectAimagNiislelBrgd.toString(),
                        sum_duureg: this.selectSumDuuregBrgd.toString(),
                    }
                }).then(({data}) => {
                    this.brigad_ajiliin_zar = data.paginate.ds_brigad_ajil_zar_view;
                    this.total = data.paginate.total;
                    // console.log("brigad_ajiliin_zar");
                    // console.log(this.brigad_ajiliin_zar);
                    setTimeout(() => {
                        this.loading = false;
                    })
                })
            } else {
                this.loading = true;
                this.$apollo.query({
                    query: GET_BRGD_AJILIIN_ZAR_PAGE, fetchPolicy: 'no-cache',
                    variables: {page: this.current_page, size: 10,}
                }).then(({data}) => {
                    this.brigad_ajiliin_zar = data.paginate.ds_brigad_ajil_zar_view;
                    this.total = data.paginate.total;
                    this.loading = false;
                })
            }
        },
        cleanFilter() {
            this.selectBrigad = '';
            this.selectTsagiinTurul = '';
            this.selectTsalinTurul = '';
            this.selectAJilTurul = '';
            this.selectAimagNiislelBrgd = '';
            this.selectSumDuuregBrgd = '';

            this.callDataBrigad();
        },
        changePageBrigad(page) {
            this.current_page = page;
            this.callDataBrigad();
        },
        getBanner() {
            this.$apollo.query({query: GET_BANNER_VIEW}).then(({data}) => {
                this.banner4 = data.ds_banner_view[3];
            })
        },
        getAimagNiislel() {
            this.$apollo.query({query: GET_AIMAG_NIISLEL}).then(({data}) => {
                this.aimag_niislelBrgd = data.ds_aimag_view;
            })
        },
        getSumDuureg() {
            this.$apollo.query({query: GET_SUM_DUUREG_ALL}).then(({data}) => {
                this.sumduuregBrgd = data.ds_sum_view;
            })
        },
    },
    mounted() {
        this.getTsagiinTurul();
        this.getTsalinTurul();
        this.getAjilTurul();
        this.callDataBrigad();
        this.getBanner();
        this.getBrigad_select();
        this.getAimagNiislel();
        this.getSumDuureg();
    },
}
</script>

<style scoped>

</style>
